export const commonWords: Set<string> = new Set([
  'a',
  'an',
  'the',
  'I',
  'i',
  'you',
  'we',
  'they',
  'he',
  'she',
  'it',
  'is',
  'are',
  'was',
  'were',
  'be',
  'been',
  'am',
  'do',
  'does',
  'did',
  'have',
  'has',
  'had',
  'in',
  'on',
  'at',
  'by',
  'to',
  'with',
  'for',
  'of',
  'from',
  'about',
  'and',
  'but',
  'or',
  'so',
  'yet',
  'nor',
  'if',
  'very',
  'much',
  'more',
  'most',
  'too',
  'this',
  'that',
]);
